@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@700&display=swap);

body{line-height:2}h1,h2,h3,h4,h5,h6{font-family:'IBM Plex Serif', serif;letter-spacing:2px}

.uk-button{border-radius:500px}

#hero .content{max-width:450px}

.section-header{text-align:center;margin-bottom:3rem}.section-header h2{margin-bottom:.75rem}.section-header span{margin-bottom:1rem;display:block}.section-header .uk-text-lead{font-size:1.25rem}

.feature{padding-left:80px;position:relative;max-width:350px;margin-bottom:2rem;text-align:left}.feature .icon{width:60px;height:60px;position:absolute;left:0;top:0;background:grey center/cover no-repeat;border-radius:50%}.feature .title{margin-bottom:.5rem}

.class{text-align:left;border-radius:20px}.class header{padding:1rem}

.subscribe-form .uk-input{border-top-left-radius:500px;border-bottom-left-radius:500px;padding-left:20px}.subscribe-form .uk-button{border-top-left-radius:0;border-bottom-left-radius:0;border-top-right-radius:500px;border-bottom-right-radius:500px}

