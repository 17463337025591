.section-header {
  text-align: center;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: .75rem;
  }

  span {
    margin-bottom: 1rem;
    display: block;
  }

  .uk-text-lead {
    font-size: 1.25rem;
  }
}